// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/opt/build/repo/frontend/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-app-js": () => import("/opt/build/repo/frontend/src/pages/app.js" /* webpackChunkName: "component---src-pages-app-js" */),
  "component---src-pages-auth-confirm-js": () => import("/opt/build/repo/frontend/src/pages/auth/confirm.js" /* webpackChunkName: "component---src-pages-auth-confirm-js" */),
  "component---src-pages-auth-login-js": () => import("/opt/build/repo/frontend/src/pages/auth/login.js" /* webpackChunkName: "component---src-pages-auth-login-js" */),
  "component---src-pages-auth-logout-js": () => import("/opt/build/repo/frontend/src/pages/auth/logout.js" /* webpackChunkName: "component---src-pages-auth-logout-js" */),
  "component---src-pages-auth-signup-js": () => import("/opt/build/repo/frontend/src/pages/auth/signup.js" /* webpackChunkName: "component---src-pages-auth-signup-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/frontend/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

